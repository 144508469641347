header {
  position: relative;
}

.header__container {
  min-height: 48px;
  background-color: #000000;

  @include tel {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @include tablet {
    min-height: 60px;
    display: flex;
  }

  @include desktop {
    min-height: 60px;
    display: flex;
  }
}

.link {
  text-decoration: none;
}

.header__logo {
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;

  @include tablet {
    width: 170px;
    height: 48px;
  }

  @include desktop {
    width: 200px;
    height: 56px;
  }
}

.container__logo {
  display: flex;
  align-items: center;
}

.logo-img {
  max-width: 32px;
  height: 32px;

  @include tablet {
    height: 48px;
    max-width: 48px;
    margin-right: 8px;
  }
}

.logo-text {
  display: none;

  @include tablet {
    display: block;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #ffffff;
  }
}

.header__list {
  display: none;

  @include tablet {
    display: flex;
    gap: 32px;
  }

  @include desktop {
    gap: 40px;
  }
}

.header__nav-link {
  display: block;
  text-align: center;
  color: #b7b7b7;

  &:hover,
  &:focus {
    transition: color 0.6s, transform 0.6s;
    transform: scale(1.2);
    color: #f87719;
  }

  @include tablet {
    padding: 22px 0;
  }
}

.current__nav-link {
  color: #f87719;
}

// MOBILE MENU NAVIGATION BLOCK

.header__nav {
  @media (min-width: 768px) {
    margin-right: 120px;
    margin-left: 59px;
  }
  @media (max-width: 767px) {
    position: absolute;
    top: 0;
    left: -64%;
    width: 64%;
    height: 100vh;
    z-index: 10;
    transition: left 0.3s ease-in-out;
    background-color: #171717;
    border-radius: 0px 8px 8px 0px;
  }
}

.mobile-menu {
  padding: 8px 20px;
  position: relative;

  @include tablet {
    display: none;
  }
}

.mobile-menu__list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 40px;
}

.mobile-menu__item {
  padding: 10px;
  text-align: left;
  color: #ffffff;

  &:hover,
  &:focus {
    transition: color 0.6s;
    color: #f87719;
  }
}

.mobile-menu__link {
  padding: 10px;
  text-align: left;
  color: #ffffff;
  text-decoration: none;

  &:hover,
  &:focus {
    transition: color 0.75s;
    color: #f87719;
  }
}

.current__nav-link {
  color: #f87719;
}

#menu {
  padding: 17px 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #b7b7b7;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover,
  &:focus {
    transition: color 0.6s;
    color: #f87719;
  }

  @include tablet {
    display: none;
  }
}

// SWITCHER

.switcher {
  width: 38px;
  height: 16px;
  padding: 4px;
  background: linear-gradient(141.22deg, #f8bb20 9.4%, #f53f16 92.9%);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px;
  cursor: pointer;

  @include tablet {
    height: 20px;
    width: 54px;
    padding: 5px;
  }

  @include desktop {
    padding: 3px;
    width: 60px;
    height: 24px;
  }
}

.switch-icon {
  align-items: center;
  display: flex;
}

.header__icon-moon {
  width: 8px;
  height: 8px;

  @include tablet {
    width: 10px;
    height: 10px;
  }

  @include desktop {
    width: 14px;
    height: 14px;
  }
}

.header__icon-sun {
  width: 8px;
  height: 8px;

  @include tablet {
    width: 11px;
    height: 11px;
  }

  @include desktop {
    width: 14px;
    height: 14px;
  }
}

.switcher__ball {
  transition-duration: 500ms;
  position: absolute;
  width: 12px;
  height: 12px;
  left: 25px;
  top: 2px;
  right: 2px;
  background-repeat: no-repeat;
  cursor: pointer;
  fill: #595959;
  background-image: url('/src/images/header/Group\ 770831.svg');
  background-size: contain;

  @include tablet {
    left: 35px;
    width: 16px;
    height: 16px;
  }

  @include desktop {
    left: 40px;
    top: 3px;
    width: 18px;
    height: 18px;
  }
}

.light .switcher__ball {
  transform: translateX(-23px) rotate(-180deg);

  @include tablet {
    transform: translateX(-33px) rotate(-180deg);
  }

  @include desktop {
    transform: translateX(-38px) rotate(-180deg);
  }
}

// OVERLAY

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(97, 89, 89, 0.2);
  z-index: 5;
  display: none;
}

// Light theme

//^ BODY
body.light {
  background-color: #ffffff;
  color: #000000;
  transition: background-color 0.25s ease-out;

  a.library-btn {
    color: #111;
    border: 1px solid var(--brend-color);
  }

  p.info__title {
    color: #fff;
  }

  h3.upcoming-content__title {
    color: #111;
  }
}

//* HEADER
body.light {
  .header__container {
    background-color: #ffffff;
  }

  .catalog__input {
    border: 1px solid #595959;
    border-radius: 8px;
    background-color: transparent;
  }
  .black-span {
    background-image: url(../images/hero/hero-white-desktop-3x.png);
  }

  a {
    color: #f87719;
  }

  p {
    color: #000000;
  }

  h2 {
    color: #000000;
  }

  .logo-text {
    color: #000000;
  }

  .header__nav-link {
    color: #595959;

    &:hover,
    &:focus {
      color: #f87719;
    }
  }

  .current__nav-link {
    color: #f87719;
  }

  // mobile-menu
  .header__nav {
    @media (max-width: 767px) {
      background-color: #ffffff;
    }
  }

  .mobile-menu__link.current__nav-link {
    color: #f87719;
  }

  .mobile-menu__link {
    color: #000000;

    &:hover,
    &:focus {
      color: #f87719;
    }
  }

  #menu-button {
    color: #595959;

    &:hover,
    &:focus {
      color: #f87719;
    }
  }

  #overlay {
    background: rgba(0, 0, 0, 0.2);
  }
  // MODAL WINDOW
  .modal-film {
    background-color: #ffffff;

    a {
      color: #f87719;
    }

    p {
      color: #000000;
    }

    h2,
    h3,
    h4,
    h5 {
      color: #000000;
    }
    span {
      color: #000000;
    }

    .stats__vote {
      background-color: #f8f8f8;
    }
    svg {
      color: #000000;
    }

    div {
      color: #000000;
    }
  }
}
