.section-catalog {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.catalog {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;

  @include tablet {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;
  }

  @include desktop {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;
  }

  &__card {
    position: relative;
    display: inline-block;
    color: aliceblue;
    border-radius: 10px;
    cursor: pointer;

    transition: scale 0.3s var(--cubic);
    scale: 1;
    scale: 1;
    @include tablet {
      width: 224px;
      height: 325px;
    }

    @include desktop {
      width: 395px;
      height: 574px;
    }

    &:hover {
      scale: 1.03;
    }
  }

  &__img-wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: inherit;

    &::after {
      content: '';
      border-radius: inherit;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(255, 0, 0, 0) 63.48%,
        rgba(0, 0, 0, 0.9) 92.16%
      );
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }

  &__info {
    //  position: absolute;
    bottom: 12px;
    left: 12px;
    width: 256px;

    @include tablet {
      width: 206px;
    }
    @include desktop {
      left: 20px;
      width: 355px;
    }
  }
}

.info {
  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__list {
    display: flex;
  }

  &__title {
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.67;
    text-transform: uppercase;

    margin-bottom: 14px;

    @include tablet {
      font-size: 12px;
      line-height: 1.5;
    }

    @include desktop {
      font-size: 20px;
      line-height: 1.17;
    }
  }

  &__descr {
    color: #b7b7b7;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.67;
    position: relative;

    @include tablet {
      line-height: 1.5;
      font-size: 11px;
    }

    @include desktop {
      font-size: 20px;
      line-height: 1.17;
    }

    &:not(:last-child) {
      margin-right: 14px;
    }

    &:first-child::after {
      position: absolute;
      right: -7px;
      content: '';
      height: 100%;
      width: 1px;
      background-color: rgb(255, 255, 255);
    }
  }
}

.catalog__stars-wrap {
  margin-right: 0px;
  width: 80px;
  height: 14px;
  font-size: 12px;
  position: relative;

  @include tablet {
    width: 66px;
    height: 10px;
    font-size: 10px;
  }
  @include desktop {
    width: 106px;
    height: 18px;
    font-size: 16px;
  }

  &::before {
    content: ' ★ ★ ★ ★ ★';
    display: block;
  }
}

.catalog__rating-active {
  position: absolute;
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;

  &::before {
    content: ' ★ ★ ★ ★ ★';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: var(--brend-color);
  }
}

.catalog__filter-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 36px;
}

.catalog__input {
  background-color: var(--main-background);
  color: var(--main-white-color);

  border: 1px solid var(--main-text-color);
  border-radius: 8px;
  width: 224px;
  height: 40px;
  padding-left: 24px;
  padding-top: 14.5px;
  padding-bottom: 14.5px;

  margin-right: 16px;

  @include tablet {
    width: 204px;
  }

  @include desktop {
    width: 334px;
  }
}
.light .catalog__input {
  color: #000000;
}
.catalog__btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--btn-background);
  border: none;
  position: relative;
  color: var(--main-text-color);
  transition: color 350ms var(--cubic);

  @include tablet {
    width: 44px;
    height: 44px;
  }
  @include desktop {
    width: 48px;
    height: 48px;
  }
  &-svg {
    fill: currentColor;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    color: var(--main-background);
  }
}

.text-sorry {
  display: none;
  font-size: 20px;
  line-height: 1.4;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
  @media screen and (min-width: 768px) {
    font-size: 24px;
    line-height: 1.33;
    margin-bottom: 24px;
  }
  @media screen and (min-width: 1280px) {
    font-size: 32px;
    line-height: 1.12;
    margin-bottom: 28px;
  }
}
