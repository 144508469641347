img {
  display: block;
  width: 100%;
}

.hero {
  padding: 0px 0px 28px;
  min-width: 320px;
  height: 380px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(
      87.8deg,
      #0e0e0e 15.61%,
      rgba(14, 14, 14, 0) 60.39%
    ),
    url('/src/images/hero/hero-people-mobile-1x.jpg');
  @media (min-device-pixel-ratio: 2),
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi),
    (min-resolution: 2dppx) {
    & {
      background-image: linear-gradient(
          87.8deg,
          #0e0e0e 15.61%,
          rgba(14, 14, 14, 0) 60.39%
        ),
        url('/src/images/hero/hero-people-mobile-2x.jpg');
    }
  }
  @media (min-device-pixel-ratio: 3),
    (-webkit-min-device-pixel-ratio: 3),
    (min-resolution: 288dpi),
    (min-resolution: 3dppx) {
    & {
      background-image: linear-gradient(
          87.8deg,
          #0e0e0e 15.61%,
          rgba(14, 14, 14, 0) 60.39%
        ),
        url('/src/images/hero/hero-people-mobile-3x.jpg');
    }
  }

  @media screen and (min-width: 768px) {
    width: 768px;
    height: 432px;
    margin-left: auto;
    margin-right: auto;

    background-image: linear-gradient(
        87.8deg,
        #0e0e0e 15.61%,
        rgba(14, 14, 14, 0) 60.39%
      ),
      url('/src/images/hero/hero-people-tablet-1x.jpg');
    @media (min-device-pixel-ratio: 2),
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi),
      (min-resolution: 2dppx) {
      & {
        background-image: linear-gradient(
            87.8deg,
            #0e0e0e 15.61%,
            rgba(14, 14, 14, 0) 60.39%
          ),
          url('/src/images/hero/hero-people-tablet-2x.jpg');
      }
    }
    @media (min-device-pixel-ratio: 3),
      (-webkit-min-device-pixel-ratio: 3),
      (min-resolution: 288dpi),
      (min-resolution: 3dppx) {
      & {
        background-image: linear-gradient(
            87.8deg,
            #0e0e0e 15.61%,
            rgba(14, 14, 14, 0) 60.39%
          ),
          url('/src/images/hero/hero-people-tablet-3x.jpg');
      }
    }
  }

  @media screen and (min-width: 1280px) {
    width: 1280px;
    height: 720px;

    background-image: linear-gradient(
        87.8deg,
        #0e0e0e 15.61%,
        rgba(14, 14, 14, 0) 60.39%
      ),
      url('/src/images/hero/hero-people-desktop-1x.jpg');
    @media (min-device-pixel-ratio: 2),
      (-webkit-min-device-pixel-ratio: 2),
      (min-resolution: 192dpi),
      (min-resolution: 2dppx) {
      & {
        background-image: linear-gradient(
            87.8deg,
            #0e0e0e 15.61%,
            rgba(14, 14, 14, 0) 60.39%
          ),
          url('/src/images/hero/hero-people-desktop-2x.jpg');
      }
      @media (min-device-pixel-ratio: 3),
        (-webkit-min-device-pixel-ratio: 3),
        (min-resolution: 288dpi),
        (min-resolution: 3dppx) {
        & {
          background-image: linear-gradient(
              87.8deg,
              #0e0e0e 15.61%,
              rgba(14, 14, 14, 0) 60.39%
            ),
            url('/src/images/hero/hero-people-desktop-3x.jpg');
        }
      }
    }
  }
}

// @include tablet {
//   width: 768px;
//   height: 432px;
//   margin-left: auto;
//   margin-right: auto;
// }
// @include desktop {
//   width: 1280px;
//   height: 720px;
// }

.hero-img {
  height: 380px;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;

  @include tablet {
    width: 482px;
    height: 432px;
    margin-left: auto;
    margin-right: auto;
  }
  @include desktop {
    width: 1280px;
    height: 720px;
  }
}

.black-span {
  opacity: 100%;
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 3;
  background-image: url(../images/hero/hero-desktop-3x.png);
  background-repeat: no-repeat;
  background-size: 100% 380px;
  @include tablet {
    background-size: 100% 432px;
  }
  @include desktop {
    background-size: 100% 720px;
  }
}

.hero-wrap__content {
  max-width: 295px;
  height: 100%;
  margin-left: 20px;
  width: 35%;
  @include tablet {
    margin-left: 32;
    max-width: 295px;
  }

  @include desktop {
    margin-top: 118px;
    width: 50%;
    max-width: 385px;
  }
}

.title {
  padding-top: 40px;
  width: 100%;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;

  color: #ffffff;

  margin-bottom: 12px;

  @include tablet {
    width: 265px;
    padding-top: 100px;
    font-size: 30px;
    line-height: 31px;
  }

  @include desktop {
    width: 385px;
    padding-top: 100px;
    font-weight: 700;
    font-size: 64px;
    line-height: 75px;
  }
}

.catalog__stars-wrap-hero {
  margin-right: 0px;
  width: 80px;
  height: 14px;
  font-size: 12px;
  position: relative;

  @include tablet {
    width: 66px;
    height: 10px;
    font-size: 10px;
  }
  @include desktop {
    width: 106px;
    height: 18px;
    font-size: 16px;
  }

  &::before {
    content: ' ★ ★ ★ ★ ★';
    display: block;
    color: #fff;
  }
}

.catalog__rating-active-hero {
  position: absolute;
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;

  &::before {
    content: ' ★ ★ ★ ★ ★';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: var(--brend-color);
  }
}

.description {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #f8f8f8;
  margin-top: 16px;
  margin-bottom: 20px;

  @include tablet {
    margin-top: 20px;
    margin-bottom: 28px;
    width: 260px;
  }
  @include desktop {
    margin-top: px;

    width: 450px;
    font-size: 16px;
    line-height: 19px;
  }
}

.watch-trailer__btn {
  padding: 12px 20px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 12px;
  line-height: 1.4;

  border: 1px solid var(--brend-color);
  background: linear-gradient(141.22deg, #ffc226 9.4%, #f84119 91.91%);
  border-radius: 74px;
  color: #111111;
  cursor: pointer;

  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1),
    background 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.watch-trailer__btn:hover,
.watch-trailer__btn:focus {
  color: var(--brend-color);
  background: #fff;
  border: 1px solid var(--brend-color);
}
