.upcoming {
  @media (min-width: 768px) {
    width: 768px;

    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 1280px) {
    width: 1280px;
  }
}

.container {
  padding-left: 20px;
  padding-right: 20px;
  width: auto;

  @media (min-width: 768px) {
    width: 768px;
    padding-left: 32px;
    padding-right: 32px;
  }

  @media (min-width: 1280px) {
    width: 1280px;
  }
}

.upcoming__title {
  padding-bottom: 24px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;

  text-transform: uppercase;

  color: var(--main-text-color);

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 1.16;
  }

  @media (min-width: 1280px) {
    padding-bottom: 28px;
  }
}

.upcoming-content {
  width: 100%;

  @media (min-width: 768px) {
    width: 704px;
  }

  @media (min-width: 1280px) {
    display: flex;
    width: 1214px;
  }
}

//? ======  ошибка отсутствия контента =========

.upcoming-error-container {
  padding-top: 80px;
  margin-left: auto;
  margin-right: auto;
}


.upcoming-error-container__text {
  margin-bottom: 24px;
  font-family: 'Roboto';

  font-size: 20px;
  line-height: 1.2;

  color: var(--main-text-color);

  @media (min-width: 768px) {
    margin-left: auto;
    margin-right: auto;
    font-size: 24px;
    text-align: center;
  }

  @media (min-width: 1280px) {
    font-size: 28px;
  }
}

.upcoming-content__desktop {
  @media (min-width: 1280px) {
    max-width: 400px;
  }
}

.upcoming-content__img {
  display: block;
  width: 100%;
  border-radius: 5px;

  @media (min-width: 768px) {
    width: 704px;
    height: 400px;
  }

  @media (min-width: 1280px) {
    width: 805px;
    height: 458px;
    margin-right: 16px;
  }
}

.upcoming-content__name {
  margin-top: 24px;
  margin-bottom: 20px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 20px;
  line-height: 1.15;

  color: var(--main-text-color);

  @media (min-width: 768px) {
    font-size: 28px;
  }

  @media (min-width: 1280px) {
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.upcoming-thumb {
  @media (min-width: 768px) {
    display: flex;
  }

  @media (min-width: 1280px) {
    flex-direction: column;
  }
}

.left {
  @media (min-width: 768px) {
    margin-right: 118px;
  }

  @media (min-width: 1280px) {
    margin-right: 0px;
  }
}

.upcoming-list__item {
  display: flex;
  padding-bottom: 12px;

  @media (min-width: 768px) {
  }

  @media (min-width: 1280px) {
  }
}

.upcoming-list__text {
  display: flex;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;

  color: #b7b7b7;

  @media (min-width: 768px) {
  }

  @media (min-width: 1280px) {
  }
}

.upcoming-list__date {
  color: var(--brend-color);
  margin-left: 58px;
}
.upcoming-list__vote {
  color: var(--second-text-color);
  margin-left: 62px;
}

.vote {
  padding: 0 8;
  background-color: var(--second-text-color);
  color: var(--grey-text-color);
  border-radius: var(--cards-border-radius);
}
.upcoming-list__pop {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  text-transform: uppercase;

  color: var(--main-text-color);

  margin-left: 78px;
}

.upcoming-list__genre {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;

  color: var(--main-text-color);

  margin-left: 108px;
  @media (min-width: 768px) {
    margin-left: 108px;
  }

  @media (min-width: 1280px) {
  }
}

.upcoming-content__title {
  margin-top: 20px;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;

  align-items: center;
  text-transform: uppercase;

  color: #b7b7b7;

  @media (min-width: 768px) {
  }

  @media (min-width: 1280px) {
  }
}

.upcoming-content__text {
  margin-top: 16px;
  font-family: 'Roboto';

  font-weight: 400;
  font-size: 14px;
  line-height: 1.43;

  color: var(--second-text-color);

  @media (min-width: 768px) {
    min-width: 625px;
  }

  @media (min-width: 1280px) {
    min-width: 390px;
  }
}

//? ======  кнопка добавления =========

.upcoming-content__btn {
  display: block;
  margin-top: 20px;
  padding: 12 24;

  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;

  border: 1px solid var(--brend-color);
  background: linear-gradient(141.22deg, #ffc226 9.4%, #f84119 91.91%);
  border-radius: 74px;
  color: #111111;
  cursor: pointer;

  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1),
    background 250ms cubic-bezier(0.4, 0, 0.2, 1),
    border 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.upcoming-content__btn:hover,
.upcoming-content__btn:focus {
  color: var(--brend-color);
  background: #fff;
  border: 1px solid var(--brend-color);
}

//? ======  кнопка удаления =========

.upcoming-content__btn-remove {
  display: block;
  margin-top: 20px;
  padding: 12 24;

  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;

  border: 1px solid var(--brend-color);
  background-color: #111111;
  border-radius: 74px;
  color: #fff;
  cursor: pointer;

  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1),
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.upcoming-content__btn-remove:hover,
.upcoming-content__btn-remove:focus {
  color: var(--brend-color);
  background-color: #fff;
  border: 1px solid var(--brend-color);
}

.hidden {
  display: none;
}

//! ================================================================
