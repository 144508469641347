.modal-hide {
  display: none;
}
.modal__wrap--our-team {
  position: fixed;
  backdrop-filter: blur(2px);
  background-color: var(--main-background-rgba);
  overflow: auto;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  z-index: 100;
}
.modal-team {
  position: relative;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 40px 10px;
  padding-bottom: 120px;

  width: 100%;
  height: 100%;

  margin: 0 auto;
  overflow: auto;

  bottom: -100%;
  left: 0;
  transition: all var(--cubic);
}
.team-modal--open {
  bottom: 0; /* изменение положения модального окна */
}
.team__title {
  font-weight: 700;
  font-size: 45px;
  line-height: 50px;
  text-align: center;
  margin-bottom: 30px;

  color: var(--brend-color);
}
.team__list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
}
.team__member {
  width: 310px;
  margin: 0 auto;
  padding: 20px 15px;
  background: var(--star-background);

  border-radius: 8px;
  &:hover {
    box-shadow: 2px 2px 4px var(--brend-color);
  }
}
.member__img {
  display: block;
  width: 280px;
  height: 280px;
  margin-bottom: 20px;
  border-radius: 5px;
  overflow: hidden;
}
.member__name {
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 7px;
  text-align: center;
  color: var(--main-background);
}
.member__pos {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 20px;
  color: var(--main-gray-color);
}
.member__social {
  display: flex;
  justify-content: center;
  gap: 40px;
}
.social__svg {
  color: #fff;
  fill: currentColor;
  cursor: pointer;
  &:hover,
  :focus {
    color: var(--brend-color-active);
  }
}
.upcoming-content__btn--team {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);

  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  padding: 15px 50px;
}
@include tablet {
  .team__list {
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px;
  }
  .modal-team {
    width: 768px;
  }
}
@include desktop {
  .team__list {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 35px;
  }
  .modal-team {
    width: 1280px;
  }
}
