@mixin tel {
  @media screen and (min-width: 320px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: 1280px) {
    @content;
  }
}
@mixin desktop-notification {
  @media screen and (min-width: 1200px) {
    @content;
  }
}
