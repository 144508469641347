.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin-bottom: 25px;
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  margin-left: -25px;
  border-radius: 50%;
  border: 3px solid #cccccc;
  border-top-color: var(--brend-color);
  animation: spinner .6s linear infinite;
  z-index: 5;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}