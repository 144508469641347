:root {
    --brend-color: #F87719;
    --brend-color-rgba:rgba(248, 119, 25, 0.8);
    --brend-color-active: #188ce8;
    --main-background: #111111;
    --main-background-rgba: rgba(17, 17, 17, 0.5);
    --btn-background: linear-gradient(141.22deg, #FFC226 9.4%, #F84119 91.91%);
    --star-background: linear-gradient(141.22deg, #F84119 9.4%, rgba(248, 159, 25, 0.68) 91.91%);
    --card-background: linear-gradient(180deg, rgba(0, 0, 0, 0) 63.48%, rgba(0, 0, 0, 0.9) 92.16%);
    ---hero-background: linear-gradient(87.8deg, #0E0E0E 15.61%, rgba(14, 14, 14, 0) 60.39%), #0E0E0E;
    --main-text-color:#FFFFFF;
    --second-text-color: #F8F8F8;
    --grey-text-color: #111111;
    --main-gray-color: #595959;
    --main-white-color: #ffffff;
    --main-black-color: #000000
    --hero-background-color: #2f303a;
    --modal-keys-color:#B7B7B7;
   

    
    --btn-border-radius: 74px;
    --icons-border-radius: 50%;
    --cards-border-radius: 5px;
    --modal-border-radius: 20px;

    --cards-box-shadow: 1px 1px 24px 2px rgba(1, 1, 1, 0.45);
    --modal-box-shadow: 1px 1px 14px 4px rgba(255, 107, 8, 0.42);

    --cubic: cubic-bezier(0.4, 0, 0.2, 1);
  }
